export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Home',
        to: '/folder',
        icon: 'cilChartPie',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Favourites',
        to: '/favourites',
        icon: 'cilHeart',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Profile',
        to: '/profile',
        icon: 'cilUser',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Reset Password',
        to: '/reset-password',
        icon: 'cilLockLocked',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Notification',
        to: '/notification',
        icon: 'cilBell',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Pricing Plans',
        to: '/pricing-plan',
        icon: 'cilCreditCard',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'AI Credit',
        to: '/ai-credit',
        icon: 'cilDollar',
      },
    ]
  }
]
