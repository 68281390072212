<template>
  <CSidebar
  class="custom-sidebar"
    unfoldable
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <!-- <CIcon
        class="c-sidebar-brand-full"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 642 134"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 110 134"
      /> -->
      <img :src="require('@/assets/images/logo.png')" alt="" class="logo">
    </CSidebarBrand>
    <CRenderFunction flat :contentToRender="navigation"/>
    <!-- <CSidebarMinimizer
      class="c-d-md-down-none"
      @click.native="$store.commit('toggle', 'sidebarMinimize')"
    /> -->
  </CSidebar>
</template>

<script>
import adminNav from "./_adminNav";
import nav from "./_nav";

export default {
  name: 'TheSidebar',
  nav,
  adminNav,
  computed: {
    show () {
      return this.$store.state.sidebarShow
    },
    minimize () {
      return this.$store.state.sidebarMinimize
    },
    navigation() {
       try {
        var role = auth.getRole();
        console.log('role',role);
        //if (role == "admin") {
          //return adminNav;
        //}
        // if (role == "normal") {
          return nav;
        // }
      } catch (error) {}
      return nav;

    },
  }
}
</script>

<style>
.logo {
  width: 100%;
  background: #fff;
  max-width: 115px;
}

.custom-sidebar {
  background: #fff;
}

.c-sidebar-brand {
  background: #0A1439 !important;
}

.custom-sidebar .c-sidebar-nav-link {
  font-size: 16px;
  line-height: normal;
}

.custom-sidebar .c-active {
  font-weight: 600;
}

.custom-sidebar .c-sidebar-nav-icon,
.custom-sidebar .c-sidebar-nav-link {
  color: #0A1439 !important;
}

.c-sidebar-nav-item:hover,
.c-sidebar-nav-item:hover .c-sidebar-nav-link {
  color: #fff !important;
  background: #0A1439 !important;
}

.c-sidebar-nav-item:hover .c-sidebar-nav-icon {
  color: #fff !important;
}

</style>
