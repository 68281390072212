export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cilChartPie',
    
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Administrator'],
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Users',
        to: '/admins/userList',
        icon: 'cilUser'
      },
      
      {
        _name: 'CSidebarNavTitle',
        _children: ['Parameters'],
      },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Building Main Classification',
      //   to: '/tenant/buildingCodeCategoryList',
      //   icon: 'cilWarning'
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Building Occupancy Classfication',
      //   to: '/tenant/buildingCodeList',
      //   icon: 'cilWarning'
      // },
      {
        _name: 'CSidebarNavItem',
        name: 'Building Code',
        to: '/tenant/buildingCodeList',
        icon: 'cilBookmark'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Occupant Load Factors',
        to: '/tenant/occupantLoadFactorList',
        icon: 'cilGrid'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Minimum Number Of Exit',
        to: '/tenant/minimumNumberOfExitList',
        icon: 'cilListNumbered'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Egress Parameter',
        to: '/tenant/EgressParameterList',
        icon: 'cilWarning'
      },
      // {
      //   _name: 'CSidebarNavTitle',
      //   _children: ['Reports'],
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Mustering',
      //   to: '/tenant/emergencyList',
      //   icon: 'cilWarning'
      // },
      
      // {
      //   _name: 'CSidebarNavTitle',
      //   _children: ['Configuration']
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'User',
      //   to: '/tenant/contactByArea',
      //   icon: 'cilWarning'
      // },

      
    ]
  }
]